.admin .header {
  margin-bottom: 20px;
}

.admin .header h1 {
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;
}

.user-search-bar {
  display: flex;
    align-items: space-between;
    margin-bottom: 20px;
    gap: 20px;
    width: 100%;
}


.admin .list {
  padding-top: 0;
  padding-bottom: 0;
}

.admin .list div.navbar {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  margin: 0 -25px 0 -25px;
  padding: 15px 25px;
}

.admin .list div.navbar a {
  color: rgb(0 0 0 / 70%);
  font-family: Roboto,sans-serif;
  font-size: 15px;
  font-weight: 500;
  margin-right: 15px;
  text-decoration: none;
  transition: color .2s ease-in-out;
}

.admin .list div.navbar a.active {
  color: rgb(92 107 192);
}

.admin .admin-item {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -25px 0 -25px;
  padding: 10px 25px;
}

.admin .admin-item .information h4 {
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.admin .admin-item .information p {
  line-height: 24px;
}

.admin .admin-item.membership {
  flex-direction: column;
  align-items: baseline;
}

/* Admin modal */
.admin-modal h4 {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  color: rgb(0 0 0 / 80%);
  font-size: 16px;
  font-weight: 400;
  margin: -10px -20px 20px -20px;
  padding: 10px 20px 15px 20px;
}

.admin-modal label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.admin-modal label span {
  color: rgb(0 0 0 / 80%);
  margin-bottom: 5px;
}

.admin-modal input {
  border-radius: 6px;
  border: 1px solid rgb(0 0 0 / 10%);
  color: rgb(0 0 0 / 80%);
  font-size: 15px;
  height: 45px;
  outline: none;
  padding: 0 15px;
  transition: .2s border ease-in-out;
}

.admin-modal input:focus {
  border-color: rgb(92 107 192);
}

.admin-modal .actions {
  align-items: center;
  display: flex;
  margin-top: 15px;
}

.admin-modal button {
  height: 40px;
  margin: 0 10px 0 5px;
}

.admin-modal .error-message {
  align-items: center;
  color: var(--danger);
  display: flex;
  font-size: 15px;
  line-height: 20px;
  margin-left: 10px;
}

.admin-modal .error-message svg {
  height: 20px;
  margin-right: 5px;
}

@media (max-width: 767px) {
  .admin .admin-item {
    flex-direction: column;
    align-items: baseline;
  }

  .admin .admin-item .button {
    margin-top: 10px;
  }

  .admin-modal .actions {
    flex-direction: column;
  }

  .admin-modal .actions .button {
    justify-content: center;
    width: 100%;
  }

  .admin-modal .error-message {
    margin-top: 15px;
  }
}




/* .admin-list {
  padding: 10px;
  max-width: 600px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.admin-item {
  border: 1px solid rgb(0 0 0 / 25%);
  padding: 15px;
}

.admin-item button {
  margin-top: 5px;
}

.admin-modal label {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.admin-modal button {
  margin-top: 10px;
} */