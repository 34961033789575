.wrapper.contact {
  /* margin-top: 95px; */
  margin-bottom: 20px;
  min-height: calc(100vh - 210px);
}

.contact .header h1 {
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;

}

.contact .item > p {
  margin-bottom: 10px;
}

.contact label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.contact label span {
  color: rgb(0 0 0 / 80%);
  margin-bottom: 5px;
}

.contact input,
.contact textarea {
  border-radius: 6px;
  border: 1px solid rgb(0 0 0 / 10%);
  color: rgb(0 0 0 / 80%);
  font-size: 15px;
  height: 45px;
  outline: none;
  padding: 0 15px;
  transition: .2s border ease-in-out;
}

.contact textarea {
  max-width: 100%;
  min-height: 70px;
  padding: 10px 15px;
  width: 100%;
}

.contact input:focus,
.contact textarea:focus {
  border-color: rgb(92 107 192);
}

.contact .actions {
  align-items: center;
  display: flex;
  margin-top: 15px;
}

.contact button {
  height: 40px;
  margin: 0 10px 0 5px;
}

.contact .error-message {
  align-items: center;
  color: var(--danger);
  display: flex;
  font-size: 15px;
  line-height: 20px;
  margin-left: 10px;
}

.contact .error-message svg {
  height: 20px;
  margin-right: 5px;
}

@media (max-width: 767px) {
  .wrapper.contact {
    min-height: calc(100vh - 244px);
  }

  .contact .actions {
    flex-direction: column;
  }

  .contact .actions .button {
    justify-content: center;
    width: 100%;
  }

  .contact .error-message {
    margin-top: 15px;
  }
}