.projects-header {
  padding: 10px;
  max-width: 800px;
  width: 100%;
}

.project-view .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-view h1 {
  color: var(--primary);
  font-size: 28px !important;
  font-weight: 600 !important;
  margin: 20px 0;
}

.projects .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.projects .header .button {
  white-space: nowrap;
}

.projects .header .button svg {
  height: 15px;
  margin-right: 5px;
}

.projects-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin: 20px 0;
}

/* No projects block */
.empty-projects {
  margin: 20px 0;
}

.empty-projects a {
  text-decoration: underline;
}

.item.project h4 span {
  background-color: rgb(0 0 0 / 5%);
  border-radius: 20px;
  color: rgb(0 0 0 / 50%);
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
  padding: 2px 10px;
}

.item.project > span {
  color: rgb(0 0 0 / 40%);
  margin-bottom: 5px;
}

.item.project p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.item.project .button.delete {
  color: rgb(0 0 0 / 80%);
  height: 40px;
}

.item.project .button.delete svg {
  height: 20px;
}

/* Project view */
.wrapper.project .header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.wrapper.project .header h1 {
  color: rgb(0 0 0 / 80%);
  font-size: 20px;
}

.wrapper.project .subtitle {
  margin-bottom: 10px;
}

.wrapper.project label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.wrapper.project label span {
  color: rgb(0 0 0 / 80%);
  margin-bottom: 5px;
}

.wrapper.project input,
.wrapper.project textarea {
  border-radius: 6px;
  border: 1px solid rgb(0 0 0 / 10%);
  color: rgb(0 0 0 / 80%);
  font-size: 15px;
  height: 45px;
  outline: none;
  padding: 0 15px;
  transition: .2s border ease-in-out;
}



.project-outcome-form {
  border-radius: 6px;
  border: 1px solid rgb(0 0 0 / 10%);
  color: rgb(0 0 0 / 80%);
  /* font-size: 15px; */
  /* height: 45px; */
  margin: 1rem 0rem;
  padding: 0.75rem 1rem;
  outline: none;
  /* padding: 0 15px; */
  transition: .2s border ease-in-out;
}
.preview-label {
  font-size: 14px;
  color: rgb(106, 106, 106);

}

.preview-value{

  font-size: 14px;
  color: rgb(16, 15, 15);
  margin-bottom: 0.5rem;
}
.wrapper.project textarea {
  max-width: 100%;
  min-height: 70px;
  padding: 10px 15px;
  width: 100%;
}

.wrapper.project input:focus,
.wrapper.project textarea:focus {
  border-color: rgb(92 107 192);
}

.wrapper.project input[type='date'] {
  padding-right: 10px;
}

.wrapper.project input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(50%);
}

.wrapper.project .item.container > .actions {
  margin-top: 20px;
  margin-left: 5px;
}

.wrapper.project .error-message {
  align-items: center;
  color: var(--danger);
  display: flex;
  justify-content: center;
}

.wrapper.project .error-message svg {
  height: 20px;
  margin-right: 5px;
}
.project-view .project-outcomes,
.project-view .project-milestones,
.project-view .project-evaluations {
  border-radius: 8px;
  border: 1px solid rgb(0 0 0 / 10%);
  margin-top: 15px;
  padding: 15px;
}

.wrapper.project .project-outcomes {
  margin-top: 5px;
}

.wrapper.project .project-outcomes > h4,
.wrapper.project .project-milestones > h4,
.wrapper.project .project-evaluations > h4 {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 -15px 15px -15px;
  padding: 0 15px 15px 15px;
}

.wrapper.project .project-evaluations > p {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  margin: 0 -15px 15px -15px;
  padding: 0 15px 15px 15px;
}

.wrapper.project .project-outcomes form > .actions,
.wrapper.project .project-milestones form > .actions,
.wrapper.project .project-evaluations form > .actions {
  margin-top: 15px;
}

.wrapper.project .project-outcomes .button,
.wrapper.project .project-milestones .button,
.wrapper.project .project-evaluations .button {
  white-space: nowrap;
}

.wrapper.project .project-outcomes .button svg,
.wrapper.project .project-milestones .button svg,
.wrapper.project .project-evaluations .button svg {
  height: 15px;
  margin-right: 5px;
}

.wrapper.project .outcome-item,
.wrapper.project .milestone-item,
.wrapper.project .evaluation-item {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  margin: 0 -15px 15px -15px;
  padding: 0 15px 15px 15px;
}

.wrapper.project .outcome-preview h4,
.wrapper.project .milestone-preview h4,
.wrapper.project .evaluation-preview h4 {
  font-size: 16px;
  font-weight: 400;
  color: rgb(0 0 0 / 80%);
  margin: 10px 0 0 0;
}

.wrapper.project .outcome-preview .actions,
.wrapper.project .milestone-preview .actions,
.wrapper.project .evaluation-preview .actions {
  align-items: center;
  margin: 10px 0 0 0;
}

.wrapper.project .outcome-preview .checkbox,
.wrapper.project .milestone-preview .checkbox,
.wrapper.project .evaluation-preview .checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
}

.wrapper.project .outcome-preview .checkbox span,
.wrapper.project .milestone-preview .checkbox span,
.wrapper.project .evaluation-preview .checkbox span {
  margin: 0 0 0 5px;
}

@media (max-width: 767px) {
  .projects-list {
    grid-template-columns: 1fr;
  }
}