.finish-invite .header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.finish-invite {
  max-width: 800px;
  margin: 0px auto;
  padding: 0px 1rem;
}
.finish-invite .header h1 {
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;
}
