.favorites .header h1 {
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;
}

.favorites-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin: 20px 0;
}

.item.favorite span {
  color: rgb(0 0 0 / 40%);
  margin-bottom: 5px;
}

.item.favorite h4 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item.favorite span {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.item.favorite p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.item.favorite .button.favorite {
  color: rgb(0 0 0 / 80%);
  height: 40px;
}

.item.favorite .button.favorite svg {
  height: 18px;
}

.item.favorite .button.favorite.active {
  color: rgb(92 107 192);
}

/* No faovrites block */
.empty-favorites {
  margin-top: 1.5rem;
}
.empty-favorites a {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .favorites-list {
    grid-template-columns: 1fr;
  }
}