.payment {
    margin-bottom: 20px;
  }
  
  .payment .header h1 {
    color: var(--primary);
    font-size: 28px;
    font-weight: 600;
    margin: 20px 0;
  }
  
  .payment .item h4 {
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    color: rgb(0 0 0 / 80%);
    font-size: 16px;
    font-weight: 400;
    margin: -10px -25px 20px -25px;
    padding: 0 25px 15px 25px;
  }
  
  .payment .item.plans {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
  
  .payment .item.plans .option {
    border-radius: 8px;
    /* border: 1px solid rgb(0 0 0 / 10%); */
    /* padding: 15px; */
    /* width: calc(100% / 3); */
  }
  
  .payment .item.plans .option h2 {
    font-size: 20px;
    font-weight: 400;
    color: rgb(0 0 0 / 80%);
  }
  
  .payment .item.plans .option p {
    color: rgb(0 0 0 / 60%);
    margin: 10px 10px 20px 0px;
  }
  
  .payment .item.plans .option .button {
    margin-top: 10px;
    white-space: nowrap;
  }
  
  .payment .item.plans .option .button svg {
    height: 15px;
    margin-right: 5px;
  }
  
  .payment .item.summary {
    margin-bottom: 15px;
  }
  
  .payment .item.form label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .payment .item.form label span {
    color: rgb(0 0 0 / 80%);
    display: flex;
    margin-bottom: 5px;
  }
  
  .payment .item.form label span div {
    background-color: rgb(0 0 0 / 5%);
    border-radius: 20px;
    color: rgb(0 0 0 / 50%);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    margin-left: 5px;
    padding: 2px 10px;
  }
  
  .payment .item.form .StripeElement {
    border-radius: 6px;
    border: 1px solid rgb(0 0 0 / 10%);
    color: rgb(0 0 0 / 80%);
    font-size: 15px;
    outline: none;
    padding: 13px 15px;
    transition: .2s border ease-in-out;
  }
  
  .payment .item.form .StripeElement--focus {
    border: 1px solid rgb(92 107 192)!important;
  }
  
  .payment .item.form .actions {
    align-items: center;
    display: flex;
    margin-top: 15px;
  }
/*   
  .payment .item.form button {
    height: 40px;
    margin: 0 10px 0 5px;
  }
   */
  .payment .item.form .error-message {
    align-items: center;
    color: var(--danger);
    display: flex;
    font-size: 15px;
    line-height: 20px;
    margin-left: 10px;
  }
  
  .payment .item.form .error-message svg {
    height: 20px;
    margin-right: 5px;
  }
  
  @media (max-width: 767px) {
    .payment .item.plans {
      flex-direction: column;
    }
  
    .payment .item.plans .option {
      width: 100%;
    }
  
    .payment .item.form .actions {
      flex-direction: column;
    }
  
    .payment .item.form .actions .button {
      justify-content: center;
      width: 100%;
    }
  
    .payment .item.form .error-message {
      margin-top: 15px;
    }
  }