.wrapper.help {
  /* margin-top: 95px; */
  margin-bottom: 20px;
  min-height: calc(100vh - 210px);
}

.help .header h1 {
  color: var(--primary);
  font-weight: 600;
  font-size: 20px;
  margin: 20px 0;
}

.help .item p {
  margin-bottom: 10px;
}

.help .item p:last-of-type {
  margin-bottom: 0;
}

.help .item a {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 767px) {
  .wrapper.help {
    min-height: calc(100vh - 244px);
  }
}