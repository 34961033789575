/* Standards */
.standards .header {
  margin: 20px 0;
}
/* 
.standards .header h1 {
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;
} */

.standards-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin: 20px 0;
}



.standards-list .ant-card-body {
  height: 100%;
}

.standards-list .card {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.standards-list .card h5{
  color: var(--primary);
}

.standards-list .card .actions {
  width: 100%;
  padding-top: 1.5rem;
  margin-top: auto;
}

/* .standards-list .card button {
  color: var(--secondary);
} */
/* Subtitles */

.subtitles .header h1 {
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;
}

.subtitles-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin: 20px 0;
}

/* Documents */
.documents .header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.documents .header h1 {
  color: rgb(0 0 0 / 80%);
  font-size: 20px;
}

.documents-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin: 20px 0;
}

.item.document h4 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item.document p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.item.document .button.favorite {
  color: rgb(0 0 0 / 80%);
  height: 40px;
}

.item.document .button.favorite svg {
  height: 18px;
}

.item.document .button.favorite.active {
  color: rgb(92 107 192);
}

/* State search dropdown */
.state-dropdown {
  position: relative;
}

.state-dropdown select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgb(255 255 255);
  border: none;
  border-bottom: 2px solid rgb(0 0 0 / 10%);
  border-radius: 8px;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 4%);
  color: rgb(0 0 0 / 80%);
  height: 50px;
  outline: none;
  padding: 0 15px;
  width: 100%;
}

.state-dropdown svg {
  height: 25px;
  position: absolute;
  top: 12.5px;
  right: 12.5px;
  color: rgb(0 0 0 / 70%);
  pointer-events: none;
}

/* State standard search */
.standard-search .header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.standard-search .header h1 {
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;
}

.standard-search form {
  margin-top: 20px;
}

.standard-search .container {
  display: flex;
  margin-top: 20px;
}

.standard-search .container input {
  background-color: rgb(255 255 255);
  border-radius: 6px;
  border: 1px solid rgb(0 0 0 / 10%);
  color: rgb(0 0 0 / 80%);
  font-size: 15px;
  height: 45px;
  margin-right: 15px;
  outline: none;
  padding: 0 15px;
  width: 100%;
}

.standard-search .container input.error {
  border-color: var(--danger);
  color: var(--danger);
}

.standard-search .error-message {
  align-items: center;
  color: var(--danger);
  display: flex;
  font-size: 15px;
  line-height: 20px;
  margin-top: 10px;
}

.standard-search .error-message svg {
  height: 20px;
  margin-right: 5px;
}

/* Document loading block */
.item.loading .skeleton {
  background-color: rgb(0 0 0 / 10%);
  border-radius: 4px;
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.item.loading .skeleton::after {
  animation: loading-slide 2s infinite;
  bottom: 0;
  content: '';
  background: linear-gradient(
    90deg,
    rgb(255 255 255 / 0%) 0,
    rgb(255 255 255 / 20%) 20%,
    rgb(255 255 255 / 60%) 60%,
    rgb(255 255 255 / 0%
  ));
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(-100%);
}

.item.loading .icon-skeleton {
  width: 35px;
  height: 35px;
  margin-bottom: 20px;
}

.item.loading .title-skeleton {
  height: 15px;
  margin-bottom: 10px;
  width: 100%;
}

.item.loading .title-skeleton.last {
  margin-bottom: 15px;
}

.item.loading .link-skeleton {
  height: 15px;
  margin-bottom: 10px;
  width: 100%;
}

.item.loading div.link-skeleton.last {
  margin-bottom: 15px;
  width: 70%;
}

.item.loading .content-skeleton {
  height: 15px;
  margin-bottom: 10px;
  width: 100%;
}

.item.loading .content-skeleton.last {
  width: 30%;
}

.item.loading .button-skeleton {
  height: 35px;
  margin-right: 10px;
  width: 20%;
}

.item.loading .button-skeleton.favorite {
  width: 50px;
}

@keyframes loading-slide {
  100% { transform: translateX(100%); }
}

@media (max-width: 767px) {
  .standards-list {
    grid-template-columns: 1fr;
  }

  .subtitles-list {
    grid-template-columns: 1fr;
  }

  .documents-list {
    grid-template-columns: 1fr;
  }

  .standard-search .container {
    flex-direction: column;
  }

  .standard-search .container input {
    margin: 0 0 15px 0;
  }

  .standard-search .error-message {
    justify-content: center;
    margin-top: 15px;
  }
}