.App {
  text-align: center;
}

svg {
  height: 12px ;
  width: 12px;
}
h5 {
  font-weight: 500 !important;
}


h4 {
  font-weight: 500 !important;
}

h1 {
  font-weight: 600 !important;

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* footer {
  position: absolute;
  width: 100vw;
  bottom: 0;
} */


#detail {
  /* min-height: calc(100vh - 64px - 10px - 40px ); */
}

#public-detail{
  min-height: calc(100vh - 60px - 24px );

}
#root {
  min-height: 100vh;
}
.pdf-view {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
  /* border: none; */
  -webkit-transition: box-shadow 0.3s ease-in-out !important;
  -moz-transition: box-shadow 0.3s ease-in-out !important;
  -ms-transition: box-shadow 0.3s ease-in-out !important;
  -o-transition: box-shadow 0.3s ease-in-out !important;
  transition: box-shadow 0.3s ease-in-out !important;
  height: 800px;
  overflow-y: scroll;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header .ant-menu-overflow {
  border: 0 !important;
}

.header .ant-btn-link {
  background-color: var(--primary-150);
}

.header .ant-btn {
  margin: 0 0.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#detail {
  max-width: 1400px;
  padding: 1rem;
  margin: auto;
}


#public-detail {
  padding-top: 85px;
}

.public-header {
  background-color: var(--primary-300);
  border-bottom: 1px solid var(--primary-300);
  box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
  height: 75px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
}

.public-header .nav-menu .ant-btn{
  font-weight: 600;
}
.public-header .nav-menu .ant-btn:active {
  color: rgb(255 255 255);
  border-color: rgb(255 255 255);
}

.public-header .nav-menu .ant-btn-background-ghost:hover {
  color: rgb(255 255 255);
  border-color: rgb(255 255 255);
}

.public-header .nav-menu .ant-btn-primary {
  color: var(--primary);
  background-color: rgb(255 255 255);
  border-color: rgb(255 255 255);
}

.public-header .ant-btn{
  font-weight: 600;
}
.public-header  .ant-btn:active {
  color: rgb(255 255 255);
  border-color: rgb(255 255 255);
}

.public-header .ant-btn-background-ghost:hover {
  color: rgb(255 255 255);
  border-color: rgb(255 255 255);
}

.public-header  .ant-btn-primary {
  color: var(--primary);
  background-color: rgb(255 255 255);
  border-color: rgb(255 255 255);
}
:root {
  /* --primary: rgb(92 107 192);
   */
  /* --primary: #3b429f;
  --secondary: #880d1e;
  --danger: #fd6764; */

  /* --primary-100: #f2f4f2;
  --primary-150: #415a771c;
  --primary-200: #778DA9;
  --primary-300: #586f89;
  --primary-400: #394359;
  --primary-500: #0D1B2A;
  --primary: #394359; */

  --primary-100: #e6eef5;
  --primary-150: #ccdceb;
  --primary-200: #80a8ce;
  --primary-300: #3373b1;
  --primary-400: #00509d;
  --primary-500: #0D1B2A;
  --primary: #00509d;



  /* --primary: #52796F; */
  --secondary-100: #f6f0f1;
  --secondary-200 : #d1b3b7;
  --secondary: #A2666F;
  --danger: #fd6764;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  /* background-color: #f7f8fa;
   */
  /* background-color: #415a771b; */
  /* background-color: #f1f7fe; */
  background-color: #f4f4f9;
  color: var(--primary-500);
  line-height: 1.5;
}

main {
  margin-top: 95px;
}

input,
button,
textarea,
select {
  font: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  overflow-wrap: break-word;
}

p {
  overflow-wrap: break-word;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
button,
textarea,
select {
  font: inherit;
}

.wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

.card-actions {
  margin-top: auto;
  padding-top: 1.5rem;
}
/* 
.button {
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  position: relative;
} */
/* 
.button::after {
  content: '';
  position: absolute;
}

.button:disabled {
  cursor: default;
  opacity: .8;
} */

.button.primary {
  background-color: rgb(130 177 255);
  border-radius: 4px;
  color: rgb(255 255 255);
  padding: 0 20px;
  transition: 0.2s background-color ease-in-out;
}

.button.primary.white {
  background-color: rgb(255 255 255);
  color: rgb(83 96 172);
  font-weight: 500;
}

.button.secondary {
  background-color: transparent;
  color: rgb(0 0 0 / 80%);
}

.button.secondary::after {
  border-radius: 20px;
  border: 1px solid rgb(0 0 0 / 15%);
  height: 100%;
  left: 0;
  top: 0;
  transition: 0.2s all ease-in-out;
  width: 100%;
}

.button.secondary:not(:disabled):hover::after {
  border-radius: 25px;
  height: calc(100% + 5px);
  left: -2.5px;
  top: -2.5px;
  width: calc(100% + 5px);
}

.button.secondary.white {
  color: rgb(255 255 255);
}

.button.secondary.white::after {
  border: 1px solid rgb(255 255 255 / 30%);
}

.item {
  background-color: rgb(255 255 255);
  border-bottom: 2px solid rgb(0 0 0 / 10%);
  border-radius: 8px;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 4%);
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.item.plans {
  background-color: transparent;
  border-bottom: unset;
  border-radius: 8px;
  box-shadow: unset;
  display: flex;
  flex-direction: column;
  padding: unset;
}

.item .icon {
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgb(0 0 0 / 15%);
  color: rgb(92 107 192);
  display: inline-flex;
  height: 35px;
  justify-content: center;
  margin-bottom: 10px;
  width: 35px;
}

.item .icon svg {
  height: 20px;
}

.item h4 {
  color: rgb(0 0 0 / 80%);
  font-size: 17px;
  margin: 5px 0;
}

.item p {
  color: rgb(0 0 0 / 60%);
  font-size: 16px;
  line-height: 26px;
}

.item .actions {
  align-items: center;
  display: flex;
  /* margin-top: 10px; */
  padding-top: 1rem;
  margin-top: auto;
}

.item .actions .button {
  margin-right: 10px;
}

.hamburg-menu {
  display: none;
}
@media (max-width: 767px) {
  .hamburg-menu {
    display: block;
    float: right;
  }

  .nav-menu {
    display: none;
  }

  .ant-popover {
    margin: 0px -2.5rem;
  }

  .public-header .ant-row {
    margin: 0px 12px
  }

  .header .ant-row {
    margin: 0px 12px
  }
}

@media (min-width: 768px) {
  .hamburg-menu {
    display: none;
  }

  .nav-menu {
    display: block;
  }
  .public-header .ant-row {
    margin: 0px auto
  }

  .header .ant-row {
    margin: 0px auto
  }
  
}



.popover-content {
  min-width: 200px;
}

.popover-content .ant-space {
  width: 100%;
}

.ant-layout-header {
  padding-inline: 16px;
}

.ant-form-item-label {
  padding: 0px !important;
}

.ant-form-item-label label {
  color: rgba(0, 0, 0, 0.65) !important;

}

.header h1 {
  color: var(--primary);
  font-size: 24px;
  font-weight: 400;

}
.header h4 {
  color: var(--primary);

}

.button-icon {
  margin-right: 8px;
}

/* .text-action-btn i {
  color: var(--secondary)
} */

.text-action-btn {
  background-color: var(--primary-100);
  /* background-color: var(--secondary-100); */
  color: var(--primary-300);
}

.text-action-btn:hover {
  background-color: var(--primary-100);
  /* background-color: var(--secondary-200); */
  opacity: 0.8;
  color: var(--primary-300);
}

.ant-btn-dangerous.text-action-btn {
  background-color: #fcd8d7 !important;
  color: var(--danger) !important;
  padding: 0.0625rem 1rem !important;

}

.ant-btn-dangerous .button-icon {
  color: var(--danger) !important;
}


.breadcrumbs {
  color: var(--primary-200);
  font-size: 14px;
}

.breadcrumbs span {
  cursor: pointer;
}

.chip.ant-tag-blue {
 
    background-color: var(--secondary-100);
    color: var(--secondary);
    border: none;

    i {
      margin-right: 4px;
    }
  }

.primary-chip.ant-tag {
  background-color: var(--primary-100);
  color: var(--primary-300);
  border: none;

  i {
    margin-right: 4px;
  }
}

.pro-badge {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 11px;
  background-color: #faad14;
  /* color: var(--primary-100); */
  color: white;
  padding: 2px 6px;
  border-radius: 6px;
}

.star-icon {
  color: #faad14;
}

.ant-layout-header {
  padding: 0px !important;
  height: 64px !important;
}

.author-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  .fa-user {
    color: var(--secondary);
    line-height: unset;
    margin-right: 4px;
  }
}


@media (max-width: 767px) {
  .blog-card {
    flex-direction: column;
    justify-content: center;
    gap: 6px;

    img {
      margin: 0px auto;

    }
  }
}

@media (min-width: 768px) {
  .blog-card {
    flex-direction: row;
    justify-content: space-between;
    gap: 12px

  }
}

.slick-arrow::before {
  color: var(--primary-200) !important;

}