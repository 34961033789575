.chat-window {
  /* background-color: var(--primary-150); */
  padding: 0px 4px;
}
.chat-message-wrapper {
  width: 100%;
  display: inline-block;

  /* display: flex; */
  flex-direction: column;
}
.agent-chat-message {
  /* float: left; */
  margin: 0.125rem 0rem;
  /* background-color:  var(--primary-150); */

  border-radius: 0.75rem;
  display: block;
  /* max-width: 60%; */
}

.user-chat-message {
  /* float: right; */
  margin: 0.125rem 0rem;
  /* background-color: #7777773d; */
  border-radius: 0.75rem;
  /* max-width: 60%; */
}

.message-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
  width: 100%;
}

.message-container {
  font-weight: 600;
  color: var(--primary-300);
}

.message-content {
  margin-left: 32px;
}
.agent-chat-message-timeline {
  /* float: left; */
  /* margin: 0rem 0.5rem;
  padding: 0rem 0.5rem; */
  margin-left: 32px;
  /* background-color: #6f889226; */
  /* border-radius: 0.75rem; */
  font-size: 12px;
  color: gray;
  display: block;
  /* margin-right: auto; */

  /* max-width: 60%; */
}

.user-chat-message-timeline {
  /* float: right; */
  /* margin: 0rem 0.5rem;
  padding: 0rem 0.5rem; */
  margin-left: 32px;

  font-size: 12px;
  /* margin-left: auto; */
  color: gray;

  /* background-color: #00c2d13d; */
  /* border-radius: 0.75rem; */
  /* max-width: 60%; */
}

.chat-message-box {
  display: inline-flex;
  flex-flow: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}

.markdown-message-container a {
  color: var(--primary);
  opacity: 0.7;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.markdown-message-container a:hover {
  color: var(--primary);
  opacity: 1;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.chat-avatar {
  font-size: 14px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-300);
  border-radius: 50%;
  /* border: 1px solid var(--primary-200); */
  background-color: #80a8ce9c;
}

.session-item span {
  font-weight: 600;
}

.session-item {
  margin: 0.5rem 0rem;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  background: white;
}
.session-item div {
  font-size: 12px;
  /* text-transform: uppercase; */
  /* letter-spacing: 1px; */
  color: grey;
}

.session-item span {
  font-weight: 600;
}

.session-item.active {
  /* background-color: var(--primary-150);
   */

  border: 1px solid var(--primary);
  span {
    color: var(--primary-400);
  }

  div {
    color: var(--primary-300);
  }
}
