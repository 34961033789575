footer {
  background-color: var(--primary-300);
  color: rgb(255 255 255);
  /* padding: 25px 0; */
  margin-top: 2px;
  /* border-top: 1px solid rgb(80 92 166); */
}


.auth-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  background-color: transparent;
  color: var(--primary-300);
  margin-top: 2px !important;

  /* padding: 24px 0; */
  /* margin-top: 12px; */
  font-size: 14px;
  /* border-top: 1px solid rgb(0 0 0 / 10%); */
}

.auth-footer .wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}
.auth-footer .options {
  display: flex;
  flex-wrap: wrap;
}

@media  (max-width: 767px) {
  .auth-footer {
    flex-direction: column;
    gap: 10px;
  }
  .auth-footer .options {
    gap: 10px;
  }
}

.auth-footer .options a {
  color: var(--primary-300);
  transition: 0.2s color ease-in-out;
}

.auth-footer img {
  display: none;
}

footer .wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

footer .options {
  align-items: center;
  display: flex;
  gap: 15px;
}

footer .options a {
  color: rgb(255 255 255 / 70%);
  transition: 0.2s color ease-in-out;
}

footer .options a:hover {
  color: rgb(255 255 255);
}

@media (max-width: 767px) {
  footer .options {
    flex-direction: column;
    gap: 10px;
  }

  footer .wrapper {
    flex-direction: column;
    gap: 10px;

  }
}
