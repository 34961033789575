.landing {
    background-color: #e8eaed;
    /* height: 200vh; */
    height: auto;

    overflow-x: hidden;
    overflow-y: auto;
}
.landing .gradient {
  background: hsla(209, 100%, 40%, 1);

  background: linear-gradient(
    0deg,
    hsla(209, 100%, 40%, 1) 20%,
    hsla(0, 0%, 100%, 1) 100%
  );

  background: -moz-linear-gradient(
    180deg,
    hsla(209, 100%, 40%, 1) 20%,
    hsla(0, 0%, 100%, 1) 100%
  );

  background: -webkit-linear-gradient(
    270deg,
    hsla(209, 100%, 40%, 1) 20%,
    hsla(0, 0%, 100%, 1) 100%
  );


  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0069CB", endColorstr="#FFFFFF", GradientType=1 );
}

.landing .glow-shadow {
    box-shadow: 0 0 100px 20px hsla(209, 100%, 40%, 0.3);
    z-index: 10;
    
}