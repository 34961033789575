.chat-page .header h1 {
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;
}

.chat-page .ant-tabs-content {
  height: calc(100% );
  /* overflow-y: clip; */
}