.subtitles .header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.subtitles .header h1 {
  color: var(--primary);
  font-size: 24px;
  font-weight: 400;
  margin: 20px 0;
}

.subtitles-list .ant-card-body {
  height: 100%;
}

.subtitles-list .card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.subtitles-list .actions {
  margin-top: auto;
  padding-top: 0.25rem;
}

.subtitle-list .card h4 {
  color: var(--primary);
}

.subtitle-list .card .actions {
  width: 100%;
  padding-top: 1.5rem;
  margin-top: auto;
}
