.wrapper.legal {
  /* margin-top: 95px; */
  margin-bottom: 20px;
}

.legal .header h1 {
  /* font-size: 20px; */
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;
}

.legal .item h2 {
  font-size: 18px;
  color: rgb(0 0 0 / 80%);
  margin-top: 20px;
}

.legal .item ul {
  padding-left: 20px;
  margin: 10px 0;
}

.legal .item li {
  color: rgb(0 0 0 / 60%);
}

.legal .item a {
  text-decoration: underline;
  cursor: pointer;
}

/* Privacy policy */
.legal.privacy-policy .item h2:first-of-type {
  margin-top: 0;
}